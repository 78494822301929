import React from "react";
import PropTypes from "prop-types";
import { GameTitleContainer, GameLogo } from "./GameTitle.styled";

const GameTitle = (props) => {
  const isValidUrl = (text) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // podržava http ili https
      "((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|" + // domen
      "localhost|" + // lokalno hostovanje
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})" + // IP adresa
      "(\\:\\d+)?(\\/.*)?$", // port i putanja
      "i"
    );
    return urlPattern.test(text);
  };

  const getDomainFromUrl = (url) => {
    return new URL(url).hostname.replace("www.", "");
  };

  return (
    <GameTitleContainer>
      <GameLogo
        src={props?.graphics.primaryLogoUrl || "/logo.jpg"}
        alt="logo"
      />
      <span style={{ fontSize: "24px" }}>
        {props?.graphics.primaryText ? (
          isValidUrl(props.graphics.primaryText) ? (
            <a
              href={props.graphics.primaryText}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDomainFromUrl(props.graphics.primaryText)}
            </a>
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: props.graphics.primaryText }}
            ></span>
          )
        ) : (
          "Cryptic DJ"
        )}
      </span>
      <span style={{ fontSize: "24px" }}>
        {props?.graphics.masterHead ? (
          isValidUrl(props.graphics.masterHead) ? (
            <a
              href={props.graphics.masterHead}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDomainFromUrl(props.graphics.masterHead)}
            </a>
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: props.graphics.masterHead }}
            ></span>
          )
        ) : (
          ""
        )}
      </span>
      {props?.graphics.secondaryLogoUrl && (
        <GameLogo
          src={props.graphics.secondaryLogoUrl}
          alt="secondary logo"
        />
      )}
    </GameTitleContainer>
  );
};

GameTitle.propTypes = {
  children: PropTypes.node,
  graphics: PropTypes.object,
};

export default GameTitle;
