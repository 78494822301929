// import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { ReactComponent as circleCommantIcon } from "../../assets/images/svg/circle-command-x.svg"

export const CircleCommandContainer = styled(circleCommantIcon)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  /* border-radius: 100%; */
  /* background-color: ${selectedTheme.colors.circleColorBackground}; */
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  padding-top: 1px;
  cursor: pointer;
`;
