import React from "react";
import PropTypes from "prop-types";
import { LifeBarContainer } from "./LifeBar.styled";

const LifeBar = (props) => {
  return (
    //Marko's change------- now it is just one element with linear-gradient receiving the prop isPlaying (PlayContent's state) which will be used for animation of gradient!!!
    <div
      style={{
        height: "75px",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <LifeBarContainer
        $isEmpty={props?.isEmpty}
        className={props?.className}
        $heightIndex={props?.heightIndex}
        $isPlaying={props?.isPlaying}
      ></LifeBarContainer>{" "}
    </div>
  );
};

LifeBar.propTypes = {
  heightIndex: PropTypes.number,
  isEmpty: PropTypes.bool,
  className: PropTypes.string,
  isPlaying: PropTypes.bool,
};

export default LifeBar;
