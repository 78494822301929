import { Box } from "@mui/system";
import styled from "styled-components";
import {ReactComponent as XIcons} from '../../assets/images/svg/x.svg'
import {ReactComponent as MusicIcons} from '../../assets/images/svg/music.svg'
import {ReactComponent as SoundIcons} from '../../assets/images/svg/sound.svg'
import {ReactComponent as LikeIcons} from '../../assets/images/svg/like.svg'

export const DetailsCardContainer = styled(Box)`
    width: 94%;
    height: ${props => props.type === 'score' ? '470px' : 'fit-content'};
    position: absolute;
    top: ${props => props.type === 'score' ? '49px' : '10px'};
    background-color: #fff;
    padding: 40px;
    padding-bottom: 0;
    text-align: left;
`
export const XIcon = styled(XIcons)`
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`
export const MusicIcon = styled(MusicIcons)`
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
`
export const SoundIcon = styled(SoundIcons)`
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
`
export const LikeIcon = styled(LikeIcons)`
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
`
export const DetailsTitle = styled('h2')`
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
    color: ${props => props?.$text ? "#201E42" : "#000"};
`
export const DetailsText = styled('p')`
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    /* color: ${props => props?.$text ? "#201E42" : "#00000080"}; */
    margin: 15px 0;
    @media screen and (max-width: 600px) {
        margin: 7px 0;
    }
`
export const DateLabel = styled('label')`
    display: block;
    font-size: 0.8rem;
    font-weight: 700;
    color: "#201E42";
    text-align: center;
`
export const ScoreBox = styled(Box)`
    width: 100%;
    height: 35px;
    margin: 15px 0;
    text-align: center;
    /* background: linear-gradient(89.63deg, #8044F1 0.15%, #201E42 21.88%); */
    background: ${props => props.gameStateColor === 2 ? 'linear-gradient(89.63deg, #8044F1 0.15%, #201E42 21.88%)' :
    //  'linear-gradient(89.63deg, #b76a6a 0.15%, #aa3737 21.88%)'
    '#fff'
     };
    color: ${props => props.gameStateColor === 2 ? '#fff' : '#000'};
`
export const Score = styled('p')`
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 5px;
`
export const BtnPlay = styled('button')`
    display: block;
    width: 130px;
    margin: 20px auto;
    background-color: #8044F1;
    color: #fff;
    border-radius: 4px;
    border: transparent;
    font-weight: 700;
    padding: 8px 0;
    cursor: pointer;
`
export const Trail = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.4rem;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #8044F1;
    color: #fff;
`