export const primaryThemeColors = {
  primaryLight: "#673ab7",
  primaryDark: "#009688",
  primaryBlue: "#0085CA",
  primaryPurple: "#624AD6",
  primaryGrey: "#ffffff11",
  background: "#linear-gradient(180deg, #0B0A1B 76.56%, #180C31 100%)",
  lifeLineGradient: "linear-gradient(360deg, #201E42 40.5%, #6B50E9 100%)",
  secondaryLight: "#212121",
  secondaryDark: "#f5f5f5",
  disc: {
    orange: "#FFC000",
    red: "#fe6a14",
    green: "#0085CA",
    white: "#FFFFFF"
  },
  circleColorBackground: "#F8CBAD",
  suggestionsBorder: "#1b1834",
  suggestionsHighlight: "#2a34d4",
  incorrectLinkBgColor: "#fa5555",
  // correctBgColor: "#31f766"
  correctBgColor: "#624AD6"
};
