import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Play } from "../../assets/images/svg/play.svg";
import { ReactComponent as Pause } from "../../assets/images/svg/pause.svg";
import { ReactComponent as Rewind } from "../../assets/images/svg/rewind.svg";
import { ReactComponent as Checked } from "../../assets/images/svg/checked.svg";


export const PlayerControllerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 90px;
  height: 50px;
`;

export const PlayBtn = styled(Play)`
  width: 45px;
  height: 35px;
  cursor: pointer;
`;
export const PauseBtn = styled(Pause)`
  position: relative;
  width: 45px;
  height: 35px;
  cursor: pointer;
`;
export const RewindBtn = styled(Rewind)`
  position: relative;
  width: 45px;
  height: 35px;
  cursor: pointer;
`;
export const CheckedIcon = styled(Checked)`
  position: relative;
  width: 45px;
  height: 35px;
`;