import { Box, TextField } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { fieldStatesColors } from "../../constants/fieldStatesColors";
import {
  CORRECT_FIELD_STATE,
  GAVEUP_FIELD_STATE,
  TIMEOUT_FIELD_STATE,
  WRONG_FIELD_STATE,
  CURRENT_FIELD_STATE,
} from "../../constants/fieldStates";

export const TextFieldContainer = styled(Box)`
  flex: 1;
  position: relative;
  border-radius: 11px;
  height: 33px;
  background-color: ${(props) =>
    props.correct
      ? fieldStatesColors.CORRECT_FIELD_STATE.backgroundColor
      : props.is_time_out
      ? props.isTextCursorActive // For TIMEOUT_FIELD_STATE, check if the text cursor is active
        ? fieldStatesColors.TIMEOUT_FIELD_STATE.focusedBackgroundColor
        : fieldStatesColors.TIMEOUT_FIELD_STATE.backgroundColor
      : props.wrong && props.isPlaying // If wrong state and the song is playing
      ? fieldStatesColors.CURRENT_FIELD_STATE.focusedBackgroundColor // Use purple when playing
      : props.wrong && props.isTextCursorActiveRealtime // If wrong state and the cursor is active
      ? fieldStatesColors.CURRENT_FIELD_STATE.focusedBackgroundColor // Use purple when cursor is active
      : props.wrong // If just wrong state and not playing or no active cursor
      ? fieldStatesColors.WRONG_FIELD_STATE.backgroundColor
      : props.gaveUp
      ? fieldStatesColors.GAVEUP_FIELD_STATE.backgroundColor
      : props.isFocused
      ? fieldStatesColors.CURRENT_FIELD_STATE.backgroundColor
      : "#ffffff25"};
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const TextFieldInput = styled(TextField)`
  width: 100%;
  /* max-width: ${(props) => {
    return props?.solved ? `calc(400px - 85px);` : `calc(400px - 160px);`;
  }}; */
  /* min-width: ${(props) =>
    props?.solved ? `calc(400px - 85px);` : `calc(400px - 160px);`}; */
  font-family: Calibri, sans-serif;
  color: ${(props) =>
    props?.isFocused
      ? fieldStatesColors.CURRENT_FIELD_STATE.color
      : props?.gaveUp
      ? fieldStatesColors.GAVEUP_FIELD_STATE.color
      : props?.$unSolved
      ? "#fff"
      : "#fff"};
  outline: 0;
  font-size: 16px;
  & * {
    outline: 0;
    padding: 0;
    font-size: 16px;
    border: 0;
    max-height: 33px;
  }
  & legend {
    display: none;
  }
  & fieldset {
    border-width: 0;
    outline: 0;
  }
  & input {
    color: ${(props) =>
      props?.gaveUp
        ? fieldStatesColors.GAVEUP_FIELD_STATE.color
        : props?.isFocused && props?.fieldState !== CORRECT_FIELD_STATE // Dodaj ovaj uslov da ne menja boju u belu za CORRECT_FIELD_STATE
        ? fieldStatesColors.CURRENT_FIELD_STATE.color
        : props?.correct
        ? fieldStatesColors.CORRECT_FIELD_STATE.color
        : props?.$unSolved
        ? "#fff"
        : "#fff"};
    /* color: white; */
    &::placeholder {
      font-weight: 600;
      color: ${(props) =>
        props?.isFocused && props?.fieldState !== CORRECT_FIELD_STATE // Dodaj ovaj uslov da ne menja placeholder boju u belu za CORRECT_FIELD_STATE
          ? fieldStatesColors.CURRENT_FIELD_STATE.color
          : "#000"};
    }
    padding: 11px 14px;
    border: 0 !important;
    text-align: center;
    font-weight: 300;

    @media (max-width: 450px) {
      font-weight: 600; /* Menja na 600 samo za ekrane manje od 450px */
    }
  }
  input::placeholder {
    color: #fff !important;
    padding: 11px 0;
    opacity: 1;
  }
  /* @media (max-width: 600px) {
    max-width: ${(props) => {
    // console.log(props);
    return props?.solved ? `calc(100vw - 85px);` : `calc(100vw - 170px);`;
  }};
  min-width: ${(props) =>
    props?.solved ? `calc(100vw - 85px);` : `calc(100vw - 170px);`};
  } */
`;
export const TextFieldCluedOutterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 33px;
  text-align: center;
  /* padding: 0 14px; */
  /* max-width: calc(400px - 85px);
  min-width: calc(400px - 85px); */

  /* @media (max-width: 600px) {
    max-width: calc(100vw - 85px);
    min-width: calc(100vw - 85px);
  } */
`;
export const TextFieldCluedContainer = styled(Box)`
  width: 100%;
  display: inline-block;
  color: white;
  white-space: pre;
  border: 0;
  outline: 0px solid transparent;
  line-height: 22px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  top: -0.5px;
  letter-spacing: 0.15008px;

  @media (max-width: 600px) {
    top: 0;
  }

  & * {
    letter-spacing: 0.15008px;
  }
  &:focus-visible {
    border: 0;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const TextFieldCluedNotHighlighted = styled(Box)`
  font-family: "Avenir Next", sans-serif;
  font-weight: 300;
  font-size: 16px;
  display: inline-block;
  color: ${(props) => (props?.$unSolved ? "#000" : "#000")};
  @media (max-width: 450px) {
    font-weight: 600;
  }
`;

export const TextFieldCluedHighlighted = styled(Box)`
  white-space: pre;
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #6352dc;
  font-weight: 700;
  font-style: italic;
`;

export const TextFieldSuggestionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 150;
  width: 100%;
  height: fit-content;
  /* max-height: 300px; */
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #dcdefb;
  bottom: 33px;
`;
export const TextFieldSingleSuggestion = styled(Box)`
  width: 100%;
  min-height: 48px;
  height: 48px;
  border: 1px solid #110b27;
  font-family: Calibri, sans-serif;
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #dcdefb;
    cursor: pointer;
  }
`;
// padding-left: 8px;

export const TextFieldSingleSuggestionText = styled.pre`
  font-family: Calibri, sans-serif;
  color: #110b27;
  font-weight: bold;
  display: inline-block;
`;
export const TextFieldSingleSuggestionTextHighlighted = styled.pre`
  font-family: Calibri, sans-serif;
  display: inline-block;
  color: white;
  background-color: ${selectedTheme.colors.suggestionsHighlight};
`;
