import { Box } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import selectedTheme from "../../themes";
import LifeBar from "../Bars/LifeBar/LifeBar";
import MusicDisc from "../MusicDisc/MusicDisc";

const LosingLife = keyframes`
  to{
    width: 0%;
  }
`

export const DiscLosingProgressContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-basis: ${props => props?.$width ? `${props?.$width}%`: "55%"};
`;
export const SinglePlayerLive = styled(Box)`
  /* height: 8px; */
  position: relative;
  height: 42px;
  width: 100%;
  min-width: 14.66px;
  background: #503287;
`;
export const LifeDisc = styled(MusicDisc)`
  width: 100%;
  height: 100%;
`;
export const LifeSingleBarAAAAAAA = styled(LifeBar)`
  width: ${props => props?.$leftTime? `${props?.$leftTime*20}%` : `100%`};
  height: 8px;
  border-radius: 1px;
  /* background: linear-gradient(89.46deg, #8044F1 10.21%, #201E42 87.42%); */
  /* background-color: #8044F1; */
  background: ${props=>props?.$leftTimeSong === 15?`linear-gradient(89.46deg, #8044F1 10.21%, #201E42 87.42%)`:`linear-gradient(89.46deg, #8044F1 10.21%, #8044F1 87.42%)`};
  border-color: ${selectedTheme.colors.primaryOrange};
  /* ${props => props?.$losingLife && css`
    animation: ${LosingLife} 5s ${props?.$index * 5}s linear;
  `} */
  transition: 1s all ease;
`;
export const LifeSingleBar = styled(Box)`
  width: ${props => props?.$leftTime === 15? `100%` : `${props?.$leftTime*6.7}%`};
  /* height: 8px; */
  height: 100%;
  border-radius: 1px;
  /* background: linear-gradient(89.46deg, #8044F1 10.21%, #201E42 87.42%); */
  /* background-color: #8044F1; */
  /* background: ${props=>props?.$leftTimeSong === 15?`linear-gradient(89.46deg, #8044F1 10.21%, #201E42 87.42%)`:`linear-gradient(89.46deg, #8044F1 10.21%, #8044F1 87.42%)`}; */
  background: #5f48bd;
  transition: 0.5s all ease;
`;
export const RewindTimeMarker = styled(Box)`
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: red;
  right: ${props => `${props.rewindTime*100/15}%`};
`


export const LifeSingleEmptyBar = styled(LifeBar)`
  width: 100%;
  height: 8px;
  border-radius: 1px;
  background: none;
  border: 1px solid #674EE1;
`;
