import { Box } from "@mui/material";
import styled from "styled-components";

export const PWALayoutContainer = styled(Box)`
  width: 400px; /* Adjust to full screen */
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box; /* Ensure no unintentional overflows */
  @media (max-width: 400px) {
    width: 360px; 
  }

  @media (max-width: 600px) {
    height: 90vh; /* Adjust height for small screens */
  }
`;
