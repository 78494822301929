import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as HelpIcon } from "../../assets/images/svg/help.svg"
import { ReactComponent as AboutIcon } from "../../assets/images/svg/about.svg"
import { ReactComponent as SubmitIcon } from "../../assets/images/svg/submit.svg"

export const IconContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999;
    font-size: 1rem;
`
export const Help = styled(HelpIcon)`
    width: 30px;
    height: 30px;
    cursor: pointer;
`
export const About = styled(AboutIcon)`
    width: 30px;
    height: 30px;
    cursor: pointer;
`
export const Submit = styled(SubmitIcon)`
    width: 30px;
    height: 30px;
    cursor: pointer;
`