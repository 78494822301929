import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { logEvent } from "firebase/analytics"; // Dodato za Firebase događaje
import { analytics } from "../../firebase/firebaseConfig"; // Dodato za Firebase konfiguraciju
import "./PlayerController2.css";
import PropTypes from "prop-types";
import {
  INITIAL_FIELD_STATE,
  TIMEOUT_FIELD_STATE,
  WRONG_FIELD_STATE,
  CORRECT_FIELD_STATE,
  OVER_FIELD_STATE,
  GAVEUP_FIELD_STATE,
} from "../../constants/fieldStates";

const TIMEOUT_DURATION = 5000;

const PlayerController2 = forwardRef((props, ref) => {
  let length = Math.PI * 2 * 100;
  const [currentPercent, setCurrentPercent] = useState(100);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false); // Flag da li je Play kliknut
  const [playedSongs, setPlayedSongs] = useState({});

  const [isOneMinutePassed, setIsOneMinutePassed] = useState(false); // Flag za minut
  const progressBar = useRef(null);
  const audioRef = useRef(null);
  const [audio] = useState(new Audio(props?.currentSongUrl));
  const fieldState = INITIAL_FIELD_STATE;

  useEffect(() => {
    // Postavi minutni tajmer
    const timer = setTimeout(() => {
      setIsOneMinutePassed(true);
      if (!hasPlayed) {
        // Ako nije kliknuo na Play, šaljemo događaj
        logEvent(analytics, "Bounce_No_Play", {
          event: "Bounce_No_Play",
          debug_mode: true,
        });
      }
    }, 60000); // 1 minut

    return () => clearTimeout(timer); // Očisti tajmer na unmount-u
  }, [hasPlayed]);

  useEffect(() => {
    if (props?.currentSongUrl) {
      audio.src = props.currentSongUrl;
      update(100, 100);
      onPause();
    }
  }, [props?.currentSongUrl]);

  useEffect(() => {
    if (
      props.scoreTimers &&
      !audio.paused &&
      !props.answersTimeOutStatus[props.currentIndex]
    ) {
      props.handleSetDecimalInterval();
    }
  }, [props.scoreTimers]);

  useEffect(() => {
    const currentPlayingProgress = props?.scoreTimers[props?.currentIndex];
    if (
      currentPlayingProgress &&
      !props.answersTimeOutStatus[props.currentIndex]
    ) {
      audio.currentTime = currentPlayingProgress;
      const currentPercent = 100 - (currentPlayingProgress / 15) * 100;
      setCurrentPercent(currentPercent);
      update(currentPercent, 100);
    } else {
      update(100, 100);
    }

    if (!props?.answersTimeOutStatus[props?.currentIndex]) {
      setIsWrong(false);
    } else {
      setIsWrong(true);
    }
  }, [props?.currentIndex]);

  const songTimeTracker2 = useCallback(() => {
    if (
      fieldState === INITIAL_FIELD_STATE ||
      fieldState === WRONG_FIELD_STATE
    ) {
      let songTime = audio.currentTime > 15 ? 15 : audio.currentTime;
      let leftTime =
        (TIMEOUT_DURATION / 1000) * 3 - songTime < 0
          ? 0
          : (TIMEOUT_DURATION / 1000) * 3 - songTime;
      props.updateScoreTimers(songTime, props.currentIndex);
      if (leftTime === 10) {
        onPause();
      }
    }
  });

  useEffect(() => {
    audio.addEventListener("timeupdate", songTimeTracker2);
    return () => {
      audio.removeEventListener("timeupdate", songTimeTracker2);
    };
  }, [songTimeTracker2]);

  function update(value, timePercent) {
    var offset = length - (length * value) / timePercent;
    progressBar.current.style.strokeDashoffset = offset;
  }

  const onPlay = () => {
    // Postavi flag da je Play kliknut
    if (!hasPlayed) {
      setHasPlayed(true);
    }
    const displayName = props?.displayName || "Unknown Song";
    if (!playedSongs?.[props.currentIndex]) {
      logEvent(analytics, `${displayName} played`, {
        content_type: "play_song",
        item_id: displayName,
      });
      setPlayedSongs((prevState) => ({
        ...prevState,
        [props.currentIndex]: true, // Obeležimo trenutnu pesmu kao "puštenu"
      }));
    }
    //Stop the forFun song and fix the double song playing issue!
    props?.songForFun.forEach((isForFun, index) => {
      if (isForFun && index !== props.currentIndex) {
        const songRef = props.songRefs?.current?.[index];

        if (songRef && songRef.song instanceof HTMLAudioElement) {
          songRef.song.pause(); // Pauziraj pesmu
        } else {
          console.log("Not valid audio element:", songRef);
        } 

        props.setSongForFun((prevState) =>
          prevState.map((el, idx) => (idx === index ? false : el))
        );
      }
    });

    setIsPlaying(true);
    // let isCurrentPlaying = true;

    props?.setIsPlaying(true);
    props.handleSetDecimalInterval();
    audio.play();
    audio.addEventListener("timeupdate", songTimeTracker2);
    audio.addEventListener("timeupdate", () => {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      const percentLeft = (1 - currentTime / duration) * 100;
      update(percentLeft, 100);
      if (percentLeft <= 0) {
        const newAnswersTimeOutStatus = props.answersTimeOutStatus.map(
          (item) => item
        );
        update(100, 100);
        newAnswersTimeOutStatus[props.currentIndex] = true;
        props.setAnswersTimeOutStatus(newAnswersTimeOutStatus);
        setIsWrong(true);
        onPause();
        props?.handleTimeUpController();
        onPause();
      }
    });
    if (
      props?.isLoadedSongs &&
      props?.isLoadedSongs[props?.currentIndex] === false
    ) {
      const newIsLoadedSongs = props?.isLoadedSongs.map((item) => item);
      newIsLoadedSongs[props?.currentIndex] = true;
      props?.setIsLoadedSongs(newIsLoadedSongs);
    }
  };
  const onPause = () => {
    // if (!PlayerController2Ref.current) {
    //   PlayerController2Ref.current.onPauseChildren();
    // }

    setIsPlaying(false);

    audio.removeEventListener("timeupdate", songTimeTracker2);
    audio.pause();
    props?.setIsPlaying(false);
    props.handleClearInterval();
  };

  const onXReveal = () => {
    const newIsGaveUpFields = props?.isGaveUpFields.map((item) => item);
    newIsGaveUpFields[props?.currentIndex] = true;
    props?.setIsGaveUpFields(newIsGaveUpFields);
  };

  useImperativeHandle(ref, () => ({
    onPauseChildren() {
      onPause();
    },
  }));

  return (
    <div className="container">
      <div className="circle">
        <svg
          width="69"
          viewBox="0 0 220 220"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(110,110)">
            <circle r="100" className="e-c-base" />
            <g transform="rotate(-90)">
              <circle
                r="100"
                className="e-c-progress"
                ref={progressBar}
                style={{
                  strokeDasharray: length,
                  strokeDashoffset: 0,
                  transition: "stroke-dashoffset 0.1s linear",
                }}
              />
              {/* <g id="e-pointer">
                <circle cx="100" cy="0" r="8" className="e-c-pointer" />
              </g> */}
            </g>
          </g>
        </svg>
      </div>
      <div className="controls">
        {/* {isWrong ? (): ()} */}
        <button
          className="play-controller"
          onClick={() => {
            if (isWrong) {
              onXReveal();
            } else if (isPlaying) {
              onPause();
            } else {
              onPlay();
            }
          }}
        >
          {isPlaying ? (
            <svg
              className="controls-playing"
              width="26"
              height="28"
              viewBox="0 0 26 28"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginBottom: "4px",
              }}
            >
              <defs>
                <filter
                  id="shadow-play"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                >
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="3"
                    floodColor="rgba(0, 0, 0, 0.5)"
                  />
                </filter>
                <linearGradient
                  id="play-gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    style={{ stopColor: "#8b7cec", stopOpacity: 1 }}
                  />
                  <stop
                    offset="50%"
                    style={{ stopColor: "#6253DB", stopOpacity: 1 }}
                  />
                  <stop
                    offset="100%"
                    style={{ stopColor: "#4a3ab8", stopOpacity: 1 }}
                  />
                </linearGradient>
              </defs>
              <rect
                x="0.230774"
                y="0.230774"
                width="10.6154"
                height="27.6"
                fill="url(#play-gradient)"
                filter="url(#shadow-play)"
              />
              <rect
                x="15.0923"
                y="0.230774"
                width="10.6154"
                height="27.6"
                fill="url(#play-gradient)"
                filter="url(#shadow-play)"
              />
            </svg>
          ) : isWrong ? (
            <svg
              width="35"
              height="43"
              viewBox="0 0 35 43"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginBottom: "2px",
              }}
            >
              <defs>
                <filter
                  id="shadow-x"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                >
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="3"
                    floodColor="rgba(0, 0, 0, 0.5)"
                  />
                </filter>
                <linearGradient
                  id="x-gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    style={{
                      stopColor: "#e76e5c",
                      stopOpacity: 1,
                    }} /* Slightly lighter */
                  />
                  <stop
                    offset="50%"
                    style={{
                      stopColor: "#e74c3c",
                      stopOpacity: 1,
                    }} /* Primary color */
                  />
                  <stop
                    offset="100%"
                    style={{
                      stopColor: "#b32e2e",
                      stopOpacity: 1,
                    }} /* Slightly darker */
                  />
                </linearGradient>
              </defs>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.94631 0.861511L0.984619 0.861558L13.8022 21.3178L0.984811 41.1392L9.19117 42.022L17.9832 27.9904L26.4616 41.5216L34.668 40.6389L22.1691 21.3099L34.6682 1.36194L26.7065 1.36189L17.9882 14.8443L8.94631 0.861511Z"
                fill="url(#x-gradient)"
                filter="url(#shadow-x)"
              />
            </svg>
          ) : (
            <svg
              width="28"
              height="34"
              viewBox="0 0 34 43"
              xmlns="http://www.w3.org/2000/svg"
              className="controls-paused"
              style={{
                marginBottom: "4px",
              }}
            >
              <defs>
                <filter
                  id="shadow-pause"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                >
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="3"
                    floodColor="rgba(0, 0, 0, 0.5)"
                  />
                </filter>
                <linearGradient
                  id="pause-gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    style={{ stopColor: "#8b7cec", stopOpacity: 1 }}
                  />
                  <stop
                    offset="50%"
                    style={{ stopColor: "#6253DB", stopOpacity: 1 }}
                  />
                  <stop
                    offset="100%"
                    style={{ stopColor: "#4a3ab8", stopOpacity: 1 }}
                  />
                </linearGradient>
              </defs>
              <path
                d="M32.4747 22.2519L2.92134 41.9542C2.7114 42.0939 2.46749 42.174 2.2156 42.1861C1.9637 42.1981 1.71326 42.1416 1.49093 42.0226C1.26861 41.9036 1.08274 41.7265 0.953109 41.5102C0.82348 41.2938 0.754952 41.0464 0.754822 40.7942V1.38978C0.754952 1.13759 0.82348 0.890171 0.953109 0.673857C1.08274 0.457543 1.26861 0.280439 1.49093 0.161409C1.71326 0.0423797 1.9637 -0.0141193 2.2156 -0.00206934C2.46749 0.00998063 2.7114 0.0901288 2.92134 0.229837L32.4747 19.9321C32.6656 20.0594 32.8222 20.2319 32.9305 20.4342C33.0387 20.6366 33.0954 20.8625 33.0954 21.092C33.0954 21.3215 33.0387 21.5474 32.9305 21.7498C32.8222 21.9521 32.6656 22.1246 32.4747 22.2519Z"
                fill="url(#pause-gradient)"
                filter="url(#shadow-pause)"
              />
            </svg>
          )}
        </button>
      </div>
      <audio ref={audioRef} src={props?.currentSongUrl} />
    </div>
  );
});

PlayerController2.propTypes = {
  currentSongUrl: PropTypes.string,
  scoreTimers: PropTypes.array,
  currentIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  handleSetDecimalInterval: PropTypes.func,
  handleSetCurrentIndex: PropTypes.func,
  setDecimalTimeToZero: PropTypes.func,
  updateScoreTimers: PropTypes.func,
  giveUpScoreTimers: PropTypes.func,
  handleClearInterval: PropTypes.func,
  setIsLoadedSongs: PropTypes.func,
  isLoadedSongs: PropTypes.array,
  answersTimeOutStatus: PropTypes.array,
  setAnswersTimeOutStatus: PropTypes.func,
  handleTimeUpController: PropTypes.func,
  setIsPlaying: PropTypes.func,
  isGaveUpFields: PropTypes.array,
  setIsGaveUpFields: PropTypes.func,
  setSongForFun: PropTypes.func,
  songForFun: PropTypes.array,
  songRefs: PropTypes.object,
  displayName: PropTypes.string,
};

PlayerController2.displayName = "PlayerController2";

export default PlayerController2;
