import { Box } from "@mui/material";
import styled from "styled-components";
import {ReactComponent as Melody} from "../../assets/images/svg/melody.svg"
import {ReactComponent as Eye} from "../../assets/images/svg/eye.svg"
import TextField from "../TextField/TextField";

export const GuessSongFieldContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
`
export const MelodyIcon = styled(Melody)`
    width: 22px;
    height: 22px;
`
export const EyeIcon = styled(Eye)`
    width: 22px;
    height: 22px;
`
export const GuessTextField = styled(TextField)`
    flex-basis: 100%;
    margin-bottom: 10px;
`
export const PlayerCommandsContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
`
export const InvisiblePlayerWrapper = styled(Box)`
    /* min-height: 15px; */
    flex-basis: 100%;
`