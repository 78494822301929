import styled from "styled-components";
export const PanelContainer = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 50%; /* Centriraj horizontalno */
  transform: translateX(-50%); /* Poravnaj element horizontalno */
  width: 380px; /* Maksimalna širina */
  max-width: 100%; /* Prilagodi manjoj širini ekrana */
  background-color: #d9dadf;
  height: ${({ isOpen }) =>
    isOpen ? "80%" : "30px"}; /* Dodajemo fiksnu visinu */
  min-height: 30px;
  max-height: 80%; /* Ograniči maksimalnu visinu */

  @media (max-width: 600px) {
    width: 99.99%;
  }
  @media (max-width: 450px) {
    height: ${({ isOpen }) =>
      isOpen ? "71%" : "30px"}; /* Dodajemo fiksnu visinu */
    max-height: 71%; /* Ograniči maksimalnu visinu */
  }
`;

export const DragIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    color: #110b27; /* Boja strelice */
    font-size: 30px; /* Povećanje veličine */
    line-height: 1; /* Podešavanje razmaka */
  }
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center; /* Vertikalna centriranost */
  border-bottom: 1px solid #6b50e9;
`;

export const Tab = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ active }) => (active ? "black" : "#a1a1a8")};
  border-bottom: ${({ active }) => (active ? "4px solid #6b50e9" : "none")};
  font-weight: bold;
  width: 120px;
  text-align: center;
  font-size: 14px; /* Podrazumevana veličina fonta */

  @media (min-width: 1346px) {
    font-size: 14px; /* Smanjuje veličinu fonta na većim ekranima */
  }
`;

export const PanelContent = styled.div`
  overflow-y: auto;
  z-index: 10;
  max-height: calc(92%);
  color: white !important;
  background: #6b50e9;
  padding-bottom: 2px;
  position: relative;

  @media (max-width: 450px) {
    max-height: calc(90%);
    padding-bottom: 2px;
  }
`;

export const CookieText = styled.div`
  justify-content: left;
  z-index: 11;
  position: relative;

  background-color: #000 !important;
  color: #fff;
  font-size: 12px;
  max-height: 88px;
  padding: 5px;
  padding-bottom: 24px;
  font-style: italic; /* Italic tekst */
  text-align: justify;

  @media (max-width: 760px) {
    padding-bottom: 29px; /* Manji padding za tablete */
  }

  @media (max-width: 546px) {
    padding-bottom: 22px; /* Manji padding za tablete */
  }

  @media (max-width: 446px) {
    padding-bottom: 22px; /* Još manji padding za mobilne uređaje */
  }
`;

export const CenteredContainer = styled.div`
  z-index: 11;

  justify-content: center;
  margin-top: 20px;
  min-height: 40px;
`;

export const ResponsiveButton = styled.button`
  z-index: 12;
  position: relative;

  background-color: #000;
  color: #fff !important;
  padding: 10px 20px;
  padding-bottom: 30px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  min-height: 20px;
`;
