export const fieldStatesColors = {
  INITIAL_FIELD_STATE: {
    backgroundColor: "#FFFFFF1C",
    color: "#FFFFFF",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  GAVEUP_FIELD_STATE: {
    backgroundColor: "#e3ffd4",
    color: "#000000",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  TIMEOUT_FIELD_STATE: {
    backgroundColor: "#ff6452",
    color: "#FFF",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  CORRECT_FIELD_STATE: {
    backgroundColor: "#B6DBA2",
    color: "#000000",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  WRONG_FIELD_STATE: {
    backgroundColor: "#FF6452",
    color: "#FFFFFF",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  OVER_FIELD_STATE: {
    backgroundColor: "#FF6452",
    color: "#FFFFFF",
    focusedBackgroundColor: "#6253DB", // Purple when editable
  },
  CURRENT_FIELD_STATE: {
    backgroundColor: "#6253DB",
    color: "#FFFFFF",
    focusedBackgroundColor: "#6253DB", // Remains purple when focused
  },
};
