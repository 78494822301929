import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  TextFieldCluedContainer,
  TextFieldCluedHighlighted,
  TextFieldCluedNotHighlighted,
  TextFieldCluedOutterContainer,
  TextFieldContainer,
  TextFieldInput,
  TextFieldSingleSuggestion,
  TextFieldSingleSuggestionText,
  TextFieldSingleSuggestionTextHighlighted,
  TextFieldSuggestionsContainer,
} from "./TextField.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectGame, selectSongs } from "../../store/selectors/gameSelectors";
import { fetchListSongs } from "../../store/actions/game/gameActions";
import {
  // TIMEOUT_FIELD_STATE,
  OVER_FIELD_STATE,
  CORRECT_FIELD_STATE,
  GAVEUP_FIELD_STATE,
  TIMEOUT_FIELD_STATE,
  WRONG_FIELD_STATE,
  INITIAL_FIELD_STATE,
} from "../../constants/fieldStates";
import { experimentalStyled } from "@mui/material";

const TextField = (props) => {
  const containerRef = useRef(null);
  const cluedRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const songs = useSelector(selectGame);
  const songAnswers = useSelector(selectSongs);
  const [placeholder, setPlaceholder] = useState("");
  const [isStarted, setIsStarted] = useState(true);
  const [hasBeenPlayedAgain, setHasBeenPlayedAgain] = useState(false); // New state to track if song has been played
  const [hasClicked, setHasClicked] = useState(false); // New state to track first click
  const timedOutSongs = useRef([]); // A ref to hold the timed-out songs, avoids unnecessary re-renders.
  const [isTextCursorActive, setIsTextCursorActive] = useState(false);
  const [delayedIsTextCursorActive, setDelayedIsTextCursorActive] =
    useState(false); // Novo stanje sa kašnjenjem
  const [delayedIsPlaying, setDelayedIsPlaying] = useState(props?.isPlaying);
  const [hasBeenWrongOnceForEachSong, setHasBeenWrongOnceForEachSong] =
    useState({}); // Object to track wrong state for each song

  const values = useMemo(() => {
    if (!songs) return [];
    return songAnswers;
  }, [songAnswers]);
  useEffect(() => {
    if (props?.searchSongs)
      dispatch(
        fetchListSongs({
          searchTerm: props?.value,
        })
      );
  }, [props?.searchSongs, props?.value]);
  useEffect(() => {
    if (props?.currentIndex === props?.songIndex) {
      setIsFocused(true);
      if (props?.fieldState !== TIMEOUT_FIELD_STATE) {
        setPlaceholder("Answer here to stop the clock");
      }
    } else if (!props?.isPlaying) {
      setIsFocused(false);
      setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
    }
  }, [props.currentIndex]);

  // useEffect(() => {
  //   console.log(`Field State for song ${props.songIndex}: ${props.fieldState}`);
  // }, [props.fieldState, props.songIndex]);

  // useEffect(() => {
  //   console.log(
  //     `Current Index: ${props.currentIndex}, Song Index: ${props.songIndex}`
  //   );
  //   console.log(`Placeholder for song ${props.songIndex}: ${placeholder}`);
  // }, [props.currentIndex, props.songIndex, placeholder]);

  useEffect(() => {
    const input = containerRef.current?.children[0]?.children[0]?.children[0];

    const checkTextCursorActive = () => {
      if (input === document.activeElement && !input.readOnly) {
        setIsTextCursorActive(true);
      } else {
        setIsTextCursorActive(false);
      }
    };

    input?.addEventListener("focus", checkTextCursorActive);
    input?.addEventListener("blur", checkTextCursorActive);

    // Initial check
    checkTextCursorActive();

    return () => {
      input?.removeEventListener("focus", checkTextCursorActive);
      input?.removeEventListener("blur", checkTextCursorActive);
    };
  }, [props?.currentIndex, props?.fieldState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedIsTextCursorActive(isTextCursorActive);
    }, 100); // Delay for the flash issue!

    return () => clearTimeout(timeout);
  }, [isTextCursorActive]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedIsPlaying(props?.isPlaying);
    }, 100); // Kašnjenje od 100ms kao i za isTextCursorActive

    return () => clearTimeout(timeout); // Očisti timeout da ne izazove curenje memorije
  }, [props?.isPlaying]);
  useEffect(() => {
    if (props?.fieldState === WRONG_FIELD_STATE) {
      setHasBeenWrongOnceForEachSong((prevState) => {
        const updatedState = { ...prevState, [props.songIndex]: true };
        return updatedState;
      });
    }
  }, [props?.fieldState, props.songIndex]);

  const handleFocusField = () => {
    if (
      props?.fieldState === OVER_FIELD_STATE ||
      props?.fieldState === CORRECT_FIELD_STATE ||
      props?.fieldState === GAVEUP_FIELD_STATE
    ) {
      return;
    }

    // Check if the placeholder has specific values and clear it when the song is clicked
    const placeholdersToClear = [
      "Answer here to stop the clock",
      "Try Again or Move On",
      "Guess, Move On, or X to Reveal (+20secs)",
      "Try again, Move On, or X to Reveal (+20s)",
    ];

    if (placeholdersToClear.includes(placeholder)) {
      setPlaceholder(""); // Očisti placeholder
    } else {
      setPlaceholder("");
    }

    // Omogućujemo klik za prvi put da pauzira ili pokrene pesmu odmah
    if (props?.currentIndex === props?.songIndex && !hasClicked) {
      // Resetuj placeholder kada kliknemo prvi put
      setHasClicked(true); // Označavamo da je pesma kliknuta

      if (props?.fieldState === TIMEOUT_FIELD_STATE) {
        setPlaceholder(""); // Očisti placeholder u TIMEOUT_STATE
      }

      // Omogućavamo pauziranje/pokretanje muzike odmah
      props?.pauseMusic?.();

      return;
    }

    // Postavljamo input na prazno kada je "Try Again or Move On"
    if (placeholder === "Try Again or Move On") {
      props?.setTextValue("");
      props?.setFieldState("INITIAL_FIELD_STATE");
      setPlaceholder("Answer here to stop the clock");
    }

    props?.onFocus();
    setIsFocused(true);
    props.setCurrentSongUrl(props?.songUrl);
    props?.setCurrentIndex(props?.songIndex);
    props?.setPlayerStatus(true);

    // Očisti placeholder kad pauziramo muziku
    if (props?.pauseMusic) {
      setPlaceholder(""); // Očisti placeholder kada je muzika pauzirana
      props.pauseMusic();
    }
  };

  const handleGuessSong = (event, songName) => {
    setIsFocused(false);
    props?.handleGuessSong(songName);
  };

  useEffect(() => {
    const input = containerRef.current?.children[0]?.children[0]?.children[0];

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Reset the clicked state after the first visibility change
        setHasClicked(false); // Reset the flag whenever visibility changes

        // Restore the placeholder for the current song
        if (props?.currentIndex === props?.songIndex) {
          // Check if the placeholder was "Try Again or Move On"
          if (placeholder === "Try again, Move On, or X to Reveal (+20s)") {
            setPlaceholder("Try again, Move On, or X to Reveal (+20s)");
          } else if (placeholder === "Try Again or Move On") {
            setPlaceholder("Try Again or Move On");
          } else if (
            placeholder === "Guess, Move On, or X to Reveal (+20secs)"
          ) {
            setPlaceholder("Guess, Move On, or X to Reveal (+20secs)");
          } else if (placeholder === "Answer here to stop the clock") {
            setPlaceholder("Answer here to stop the clock");
          } else {
            setPlaceholder(
              props.isPlaying
                ? "Answer here to stop the clock"
                : "SONG " + (Number(props.songIndex) + 1).toString()
            );
          }
        }
      }
    };

    // If the current song is the one playing
    if (props.isPlaying && props?.currentIndex === props?.songIndex) {
      setPlaceholder("Answer here to stop the clock");
      input?.removeAttribute("readonly");
      setHasBeenPlayedAgain(true); // Mark the song as played again
    }
    // If the current song is paused but still in focus
    else if (!props.isPlaying && props?.currentIndex === props?.songIndex) {
      // Ovde dodajemo logiku koja obezbeđuje da placeholder bude prazan kada je polje fokusirano
      if (hasBeenPlayedAgain || props?.fieldState === TIMEOUT_FIELD_STATE) {
        if (
          (input === document.activeElement &&
            placeholder === "Answer here to stop the clock") ||
          (input === document.activeElement &&
            placeholder === "Try Again or Move On")
        ) {
          setPlaceholder(""); // Prazni placeholder kad polje postane fokusirano
        }
        input?.removeAttribute("readonly");
      } else {
        setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
        input?.setAttribute("readonly", "true");
      }
    }

    // If switching to a different song
    else {
      input?.setAttribute("readonly", "true");
      setHasBeenPlayedAgain(false); // Reset the flag when switching songs
    }

    // If the answer is correct, set the input to readonly and ensure no cursor appears
    if (
      props?.fieldState === CORRECT_FIELD_STATE ||
      props?.fieldState === GAVEUP_FIELD_STATE
    ) {
      input?.setAttribute("readonly", "true");
    }

    // Add the visibility change event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Clean up the event listener
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    props.isPlaying,
    props?.currentIndex,
    props?.songIndex,
    hasBeenPlayedAgain,
    props?.fieldState,
    placeholder,
  ]);

  const onInput = (e) => {
    props?.changeTextValue(e.target.value);
  };
  // useEffect(() => {
  //   if(isFocused){
  //     console.log('fokusiran')
  //   }
  //   else{
  //     console.log('nije fokusiran')
  //   }
  // },[isFocused])//after time's up if focus on input and unfocus without trying to hit the answer input value becomes an empty string...here is the key of fixing that bug...we need to send to the parent somehow prop isFocused and mix that with global state of fields or to put field state from parent to this child component!!!we will see
  useEffect(() => {
    const input = containerRef.current?.children[0]?.children[0]?.children[0]; // Reference to input element

    // Specific handling for TIMEOUT_FIELD_STATE
    if (props?.fieldState === TIMEOUT_FIELD_STATE) {
      // Ensure the input is focused when we return to the field
      if (input === document.activeElement && !input.readOnly) {
        setIsTextCursorActive(true);
        setIsFocused(true); // Ljubičasta pozadina
        setPlaceholder(""); // Prazan placeholder dok kucamo
        input.focus(); // Fokusiraj input i omogući treperenje kursora
      } else {
        setIsTextCursorActive(false);
        setIsFocused(false); // Crvena pozadina ako polje nije u fokusu

        // Proveravamo da li je pesma imala `WRONG_FIELD_STATE`
        if (hasBeenWrongOnceForEachSong[props.songIndex]) {
          setPlaceholder("Try again, Move On, or X to Reveal (+20s)");
        } else {
          setPlaceholder("Guess, Move On, or X to Reveal (+20secs)");
        }
      }
    }

    // Rešavanje GAVEUP i WRONG state-a
    if (props?.fieldState === GAVEUP_FIELD_STATE) {
      setPlaceholder("Try Again or Move On");
    }

    // Specific handling for WRONG_FIELD_STATE
    if (props?.fieldState === WRONG_FIELD_STATE) {
      if (input === document.activeElement && !input.readOnly) {
        // Kada je fokusirano, resetujemo placeholder na ljubičasti
        setIsTextCursorActive(true);
        setIsFocused(true); // Ljubičasta pozadina
        setPlaceholder(""); // Prazan placeholder dok kucamo
        input.focus(); // Fokusiraj input i omogući treperenje kursora
      } else {
        setIsTextCursorActive(false);
        setIsFocused(false); // Narandžasta pozadina kada nije fokusirano
        setPlaceholder("Try Again or Move On"); // Postavljamo narandžasti placeholder kada nije fokusirano
      }
    }

    // Događaj koji osigurava da polje reaguje na fokus
    const handleFocus = () => {
      if (
        (props?.fieldState === TIMEOUT_FIELD_STATE ||
          props?.fieldState === WRONG_FIELD_STATE) &&
        input === document.activeElement
      ) {
        setIsFocused(true); // Aktiviraj ljubičastu pozadinu
        setPlaceholder(""); // Prazan placeholder kad se fokusira
        setIsTextCursorActive(true); // Aktivira treperenje kursora
      }
    };

    input.addEventListener("focus", handleFocus);

    return () => {
      input.removeEventListener("focus", handleFocus);
    };
  }, [
    props?.readOnly,
    props?.fieldState,
    props?.currentIndex,
    props.isPlaying,
  ]);

  // Drugačiji useEffect za praćenje kada se ponovo da pogrešan odgovor i vraćanje "Try Again or Move On"
  useEffect(() => {
    // Kada se polje vrati u WRONG_FIELD_STATE posle pogrešnog odgovora, postavi placeholder
    if (props?.fieldState === WRONG_FIELD_STATE && !isFocused) {
      setPlaceholder("Try Again or Move On");
    }
  }, [props?.fieldState, isFocused]);

  // useEffect(() => {
  //   console.log(`Field state for song ${props?.songIndex}: ${props?.fieldState}`);
  //   console.log(`Is the song currently playing for song ${props?.songIndex}? ${props?.isPlaying}`);
  // }, [props?.fieldState, props?.songIndex, props?.isPlaying]);

  //Handle case when giving wrong answers for a field that has a time out state! placeholder red and correct!!!
  // Handle case when giving wrong answers for a field that has a time out state! placeholder red and correct!!!
  useEffect(() => {
    const input = containerRef.current?.children[0]?.children[0]?.children[0]; // Reference to the input element

    // Reset field state and placeholder when the song loses focus only if the song isn't finished
    if (
      props?.currentIndex !== props?.songIndex &&
      props?.fieldState !== CORRECT_FIELD_STATE && //Proveravamo da li je pesma završena
      props?.fieldState !== TIMEOUT_FIELD_STATE // Ili je isteklo vreme
    ) {
      setIsFocused(false);

      // Proveri da li je stanje WRONG_FIELD_STATE i resetuj ga
      if (props?.fieldState === WRONG_FIELD_STATE) {
        props?.setFieldState("INITIAL_FIELD_STATE"); // Osloni se na props da postavi state
      }

      // Setuj placeholder nazad na ime pesme samo ako pesma nije završena
      setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
    } else {
      // Handle logic kada je pesma fokusirana
      if (
        props?.fieldState === TIMEOUT_FIELD_STATE &&
        !isTextCursorActive &&
        !placeholder &&
        !hasBeenWrongOnceForEachSong[props.songIndex]
      ) {
        setPlaceholder("Guess, Move On, or X to Reveal (+20secs)");
      }
      if (
        props?.fieldState === TIMEOUT_FIELD_STATE &&
        !isTextCursorActive &&
        !placeholder &&
        hasBeenWrongOnceForEachSong[props.songIndex]
      ) {
        setPlaceholder("Try again, Move On, or X to Reveal (+20s)");
      }

      // Ako pesma nije pauzirana (i dalje se pušta), resetujemo na ljubičasti prazni placeholder
      if (
        props?.fieldState === WRONG_FIELD_STATE &&
        props.isPlaying &&
        input === document.activeElement // Proveravamo da li je polje trenutno fokusirano
      ) {
        setPlaceholder(""); // Resetujemo placeholder na prazno
      }
    }

    // Event listener za proveru da li je input fokusiran ili ne
    const checkTextCursorActive = () => {
      if (input === document.activeElement && !input.readOnly) {
        setIsTextCursorActive(true);
        if (props?.fieldState === TIMEOUT_FIELD_STATE) {
          setPlaceholder(""); // Očisti placeholder kada polje postane fokusirano
        }
      } else {
        setIsTextCursorActive(false);
      }
    };

    input?.addEventListener("focus", checkTextCursorActive);
    input?.addEventListener("blur", checkTextCursorActive);

    // Početna provera
    checkTextCursorActive();

    return () => {
      input?.removeEventListener("focus", checkTextCursorActive);
      input?.removeEventListener("blur", checkTextCursorActive);
    };
  }, [
    props?.currentIndex,
    props?.songIndex,
    props?.fieldState,
    isTextCursorActive,
    placeholder,
    props.isPlaying, // Dodato kako bi se osigurala ponovna renderovanja pri promeni stanja pesme
    hasBeenWrongOnceForEachSong,
  ]);

  useEffect(() => {
    if (props?.isPlaying && props?.currentIndex === props?.songIndex) {
      setIsFocused(true);
      setPlaceholder("Answer here to stop the clock");
    }
  }, [props.isPlaying]);

  return (
    <TextFieldContainer
      ref={containerRef}
      onFocus={handleFocusField}
      isTextCursorActive={delayedIsTextCursorActive} // Pass the delayedIsTextCursorActive so that we don't have the color flash problem when answering!!!
      isTextCursorActiveRealtime={isTextCursorActive}
      backgroundColor={props?.backgroundColor}
      className={props?.className}
      gaveUp={props?.fieldState === GAVEUP_FIELD_STATE}
      correct={props?.fieldState === CORRECT_FIELD_STATE}
      wrong={
        props?.fieldState === WRONG_FIELD_STATE ||
        props?.fieldState === TIMEOUT_FIELD_STATE
      }
      is_time_out={props?.fieldState === TIMEOUT_FIELD_STATE}
      isFocused={isFocused}
      isPlaying={delayedIsPlaying}
    >
      {props?.clue && (
        <TextFieldCluedOutterContainer onClick={() => props.activatePlayer()}>
          <TextFieldCluedContainer
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
            // contentEditable
            ref={cluedRef}
          >
            <TextFieldCluedNotHighlighted $unSolved={props?.unSolved}>
              {props?.clue?.[0]}
            </TextFieldCluedNotHighlighted>
            <TextFieldCluedHighlighted>
              {props?.clue?.[1]}
            </TextFieldCluedHighlighted>
            <TextFieldCluedNotHighlighted $unSolved={props?.unSolved}>
              {props?.clue?.[2]}
            </TextFieldCluedNotHighlighted>
          </TextFieldCluedContainer>
        </TextFieldCluedOutterContainer>
      )}
      {!props?.clue && (
        <TextFieldInput
          // placeholder={props?.songIndex === 0 &&
          // !props?.readOnly ? "Answer" : ""}
          placeholder={placeholder}
          $unSolved={props?.unSolved}
          isFocused={isFocused}
          gaveUp={props?.fieldState === GAVEUP_FIELD_STATE}
          correct={props?.fieldState === CORRECT_FIELD_STATE}
          {...props}
          inputProps={{ autocomplete: "off" }}
          onChange={onInput}
        />
      )}
      {props?.value?.length > 0 && isFocused && !props?.hideSuggestions && (
        <TextFieldSuggestionsContainer
          onClick={(event) => event.preventDefault()}
        >
          {values?.map((singleValue) => {
            if (
              !singleValue?.toLowerCase()?.includes(props?.value?.toLowerCase())
            )
              return;
            const firstValue = singleValue?.slice(
              0,
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase())
            );
            const secondValue = singleValue?.slice(
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()),
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()) +
                props?.value?.toLowerCase()?.length
            );
            const thirdValue = singleValue?.slice(
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()) +
                props?.value?.toLowerCase()?.length
            );
            return (
              <TextFieldSingleSuggestion
                key={singleValue}
                onClick={(event) => handleGuessSong(event, singleValue)}
              >
                <TextFieldSingleSuggestionText>
                  {firstValue}
                </TextFieldSingleSuggestionText>
                <TextFieldSingleSuggestionTextHighlighted>
                  {secondValue}
                </TextFieldSingleSuggestionTextHighlighted>
                <TextFieldSingleSuggestionText>
                  {thirdValue}
                </TextFieldSingleSuggestionText>
              </TextFieldSingleSuggestion>
            );
          })}
        </TextFieldSuggestionsContainer>
      )}
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  backgroundColor: PropTypes.string,
  handleGuessSong: PropTypes.func,
  onFocus: PropTypes.func,
  setTextValue: PropTypes.func,
  hideSuggestions: PropTypes.bool,
  clue: PropTypes.bool,
  searchSongs: PropTypes.number,
  solved: PropTypes.bool,
  unSolved: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  fieldState: PropTypes.string,
  songIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  activatePlayer: PropTypes.func,
  songUrl: PropTypes.string,
  setCurrentSongUrl: PropTypes.func,
  setPlayerStatus: PropTypes.func,
  pauseMusic: PropTypes.func,
  changeTextValue: PropTypes.func,
  setFieldState: PropTypes.func,
  isPlaying: PropTypes.bool,
};

export default TextField;
