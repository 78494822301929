import { initializeApp } from "firebase/app"
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";



const firebaseConfig = {
  apiKey: "AIzaSyCwLy8AoG0XvN-BH3x6ude79yPPbZIP-Is",
  authDomain: "music-app-c3653.firebaseapp.com",
  projectId: "music-app-c3653",
  storageBucket: "music-app-c3653.appspot.com",
  messagingSenderId: "419635796384",
  appId: "1:419635796384:web:a95d871d540025a8676cc5",
  measurementId: "G-MCM9JRSF76",
}


// Initialize Firebase
export const app = initializeApp(firebaseConfig)


export const analytics = getAnalytics(app);


setAnalyticsCollectionEnabled(analytics, true);
