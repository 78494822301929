import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import {
  DetailsCardContainer,
  DetailsTitle,
  DetailsText,
  XIcon,
  // MusicIcon,
  // SoundIcon,
  // LikeIcon,
  DateLabel,
  ScoreBox,
  Score,
  BtnPlay,
  Trail,
} from "./DetailsCard.styled";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../index";

const DetailsCard = (props) => {
  //using for every card that can popup---just add new condition of context prop and make here that new cart with layout
  const { t } = useTranslation();
  const date = useMemo(() => {
    let date = new Date();

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }, []);

  const handleShare = () => {
    //sharing data through applications
    const shareData = {
      title: "Cryptic DJ",
      text: `I got today's Cryptic DJ in ${props?.score} seconds - can you beat that?`,
      url: "https://cryptic-dj.azurewebsites.net/",
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          logEvent(analytics, "Shared_the_game", {
            content_type: "share",
            item_id: "share from score card",
          });
        }) //ovde dodaj toast message!!!
        .catch((err) => console.log(err));
    } else {
      console.log("WEB SHARE API doesn`t work!");
    }
  };

  return (
    <>
      {/* {props?.cardType === "help" && (
        <DetailsCardContainer>
          <XIcon onClick={() => props?.setCardType("")} />
          <DetailsTitle>How to Play Cryptic DJ</DetailsTitle>
          <DetailsText style={{ fontSize: "1.1rem" }}>
            Guess the songs quickly, then guess what links some of the words you
            see
          </DetailsText>
          <DetailsText>
            <span
              style={{
                display: "block",
                width: "100%",
                height: "1px",
                margin: "0 auto",
                backgroundColor: "grey",
              }}
            ></span>
          </DetailsText>
          <Trail>1</Trail>
          <DetailsText>Play songs and quickly guess them</DetailsText>
          <Trail>2</Trail>
          <DetailsText>
            Pause to stop the clock, Replay, Pass or Give up (X)... but giving
            up{" "}
            <span style={{ color: "red", fontWeight: "900" }}>
              adds 20 seconds
            </span>{" "}
            to your time
          </DetailsText>
          <Trail>3</Trail>
          <DetailsText>
            Get any 3 songs, then spot what links words in the{" "}
            <span style={{ fontWeight: "900" }}>Titles or Artists</span>
          </DetailsText>
          <DetailsText>
            <span
              style={{
                display: "block",
                width: "100%",
                height: "1px",
                margin: "0 auto",
                backgroundColor: "grey",
              }}
            ></span>
          </DetailsText>
          <DetailsText>
            If you can&apos;t see the link, you can get clues on which words to
            focus on... but getting clues{" "}
            <span style={{ color: "red", fontWeight: "900" }}>
              adds 10 seconds
            </span>{" "}
            to your final time
          </DetailsText>
          <DetailsText>
            Guess the link in the quickest time, then challenge your friends!
          </DetailsText>
          <DetailsText>(New game every day at midnight)</DetailsText>
          <BtnPlay onClick={() => props?.setCardType("")}>
            START PLAYING
          </BtnPlay>
        </DetailsCardContainer>
      )}
      {props?.cardType === "about" && (
        <DetailsCardContainer>
          <XIcon onClick={() => props?.setCardType("")} />
          <DetailsTitle>Cryptic DJ</DetailsTitle>
          <DetailsText
            style={{ fontWeight: "700", fontSize: "1.2rem", color: "#8044F1" }}
          >
            Words in Music
          </DetailsText>
          <DetailsText>
            Turns random music knowledge into a word game.
          </DetailsText>
          <DetailsText>
            A mix of music rediscovery and mental agility, our questions are
            curated by musophiles rather than robots.
          </DetailsText>
          <DetailsText>
            Guess the songs quickly, then spot what links the words you see.
          </DetailsText>
          <DetailsText>
            We think that once you&apos;ve played Cryptic DJ, you&apos;ll never
            listen to the radio the same way again.
          </DetailsText>
          <DetailsText>
            <span
              style={{
                display: "block",
                width: "70%",
                height: "1px",
                margin: "0 auto",
                backgroundColor: "skyblue",
              }}
            ></span>
          </DetailsText>
          <DetailsText style={{ fontWeight: "500" }}>
            Copyrights are acknowledged and royalties are paid
          </DetailsText>
        </DetailsCardContainer>
      )}
      {props?.cardType === "submit" && (
        <DetailsCardContainer>
          <XIcon onClick={() => props?.setCardType("")} />
          <DetailsTitle>Examples</DetailsTitle>
          <DetailsText>
            It could be that Song Titles or Artists contain:
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...boat...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...train...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...plane...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...bicycle...
          </DetailsText>
          <DetailsText>So the link is...</DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            &apos;vehicles&apos;
          </DetailsText>
          <DetailsText>
            <span
              style={{
                display: "block",
                width: "100%",
                height: "1px",
                margin: "0 auto",
                backgroundColor: "black",
              }}
            ></span>
          </DetailsText>
          <DetailsText>
            It could be that Song Titles or Artists contain:
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...book...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...baby...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...time...
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            ...paint...
          </DetailsText>
          <DetailsText>So the link is...</DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0" }}>
            &apos;face&apos;
          </DetailsText>
          <DetailsText>
            <span
              style={{
                display: "block",
                width: "100%",
                height: "1px",
                margin: "0 auto",
                backgroundColor: "black",
              }}
            ></span>
          </DetailsText>

          <DetailsTitle>Calling Cryptic DJs...</DetailsTitle>
          <DetailsText>
            Want to get behind the decks with a challenge of your own?...
          </DetailsText>
          <DetailsText style={{ margin: "0" }}>
            Message us via Twitter
          </DetailsText>
          <DetailsText style={{ color: "#8044F1", margin: "0 0 10px 0" }}>
            <a
              style={{ textDecoration: "none" }}
              href="https://twitter.com/thecrypticdj"
              target="_blank"
              rel="noreferrer"
            >
              @TheCrypticDJ
            </a>
          </DetailsText>
        </DetailsCardContainer>
      )} */}
      {props?.cardType === "score" && (
        <DetailsCardContainer type="score">
          <DetailsText $text="subtitle">{t("explanation.score")}</DetailsText>
          {props?.gameState === 2 && (
            <DetailsTitle $text="score">{t("game.scoreTitle")}</DetailsTitle>
          )}
          <DateLabel>
            {t("game.scoreDate")}
            {date}
          </DateLabel>
          <ScoreBox gameStateColor={props?.gameState}>
            {props?.gameState === 2 && <Score>{props?.score}</Score>}
            {props?.gameState === 1 && (
              <Score>You didn&apos;t get the link :(</Score>
            )}
          </ScoreBox>
          <DetailsText
            onClick={handleShare}
            $text="score"
            style={{
              textDecoration: "underline",
              fontStyle: "italic",
              cursor: "pointer",
            }}
          >
            Challenge a friend to beat your time
          </DetailsText>
        </DetailsCardContainer>
      )}
    </>
  );
};

DetailsCard.propTypes = {
  cardType: PropTypes.string,
  setCardType: PropTypes.func,
  score: PropTypes.number,
  gameState: PropTypes.number,
};

export default DetailsCard;
