import React from "react";
import "./Footer.scss";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebaseConfig";

const FooterHistory = () => {
  const handleInstagramClick = () => {
    logEvent(analytics, "Clicked_Instagram", {
      platform: "Instagram",
    });
  };

  const handleFacebookClick = () => {
    logEvent(analytics, "Clicked_Facebook", {
      platform: "Facebook",
    });
  };

  const handleXClick = () => {
    logEvent(analytics, "Clicked_X", {
      platform: "X",
    });
  };
  return (
    <div className="footer-history-wrapper">
      See yesterday&apos;s game on
      <ul className="footer-history">
        <li>
          <a
            href="https://www.instagram.com/crypticdj/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleInstagramClick}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 0.533325C1.79086 0.533325 0 2.32418 0 4.53332V16.4495C0 18.6586 1.79086 20.4495 4 20.4495H15.9161C18.1253 20.4495 19.9161 18.6586 19.9161 16.4495V4.53333C19.9161 2.32419 18.1253 0.533325 15.9161 0.533325H4Z"
                fill="url(#paint0_linear_2007_1164)"
              />
              <path
                d="M13.3867 6.09375C12.9598 6.09375 12.604 6.44952 12.604 6.87644C12.604 7.30336 12.9598 7.65913 13.3867 7.65913C13.8136 7.65913 14.1694 7.30336 14.1694 6.87644C14.1694 6.44952 13.8136 6.09375 13.3867 6.09375Z"
                fill="white"
              />
              <path
                d="M9.90003 7.08984C8.12119 7.08984 6.62695 8.58407 6.62695 10.3629C6.62695 12.1418 8.12119 13.636 9.90003 13.636C11.6789 13.636 13.1731 12.1418 13.1731 10.3629C13.1731 8.51292 11.75 7.08984 9.90003 7.08984ZM9.90003 12.4264C8.76157 12.4264 7.83657 11.5014 7.83657 10.3629C7.83657 9.22445 8.76157 8.29945 9.90003 8.29945C11.0385 8.29945 11.9635 9.22445 11.9635 10.3629C11.9635 11.5014 11.1096 12.4264 9.90003 12.4264Z"
                fill="white"
              />
              <path
                d="M12.533 16.9802H7.19652C4.99076 16.9802 3.21191 15.2014 3.21191 12.9956V7.73022C3.21191 5.52445 4.99076 3.74561 7.19652 3.74561H12.4619C14.6677 3.74561 16.4465 5.52445 16.4465 7.73022V12.9956C16.5177 15.2014 14.7388 16.9802 12.533 16.9802ZM7.19652 4.95522C5.70229 4.95522 4.49268 6.16483 4.49268 7.65906V12.9244C4.49268 14.4187 5.70229 15.6283 7.19652 15.6283H12.4619C13.9561 15.6283 15.1657 14.4187 15.1657 12.9244V7.65906C15.1657 6.16483 13.9561 4.95522 12.4619 4.95522H7.19652Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2007_1164"
                  x1="-0.501304"
                  y1="0.0399983"
                  x2="20.3398"
                  y2="20.881"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.0011048" stopColor="#FFE185" />
                  <stop offset="0.2094" stopColor="#FFBB36" />
                  <stop offset="0.3765" stopColor="#FF5176" />
                  <stop offset="0.5238" stopColor="#F63395" />
                  <stop offset="0.7394" stopColor="#A436D2" />
                  <stop offset="1" stopColor="#5F4EED" />
                </linearGradient>
              </defs>
            </svg>
          </a>
        </li>

        <li>
          <a
            href="https://www.facebook.com/people/Cryptic-DJ/61561112885076/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleFacebookClick}
          >
            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6162 22.9999C17.9674 22.9999 23.1161 17.8512 23.1161 11.5C23.1161 5.1487 17.9674 0 11.6162 0C5.26492 0 0.116211 5.1487 0.116211 11.5C0.116211 17.8512 5.26492 22.9999 11.6162 22.9999Z"
                fill="#127AF6"
              />
              <path
                d="M12.901 18.8881V12.4635H15.0211L15.4065 9.82949H12.8367V8.09485C12.8367 7.38815 13.2222 6.68145 14.3144 6.68145H15.4708V4.43285C15.4708 4.43285 14.4428 4.24011 13.4149 4.24011C11.2948 4.24011 9.94565 5.52503 9.94565 7.83787V9.82949H7.63281V12.4635H9.94565V18.8881H12.901Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
        <li className="xIcon">
          <a
            href="https://x.com/TheCrypticDJ"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleXClick}
          >
            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0161 22.9999C18.3673 22.9999 23.516 17.8512 23.516 11.5C23.516 5.1487 18.3673 0 12.0161 0C5.66482 0 0.516113 5.1487 0.516113 11.5C0.516113 17.8512 5.66482 22.9999 12.0161 22.9999Z"
                fill="url(#paint0_linear_2007_1153)"
              />
              <path
                d="M12.9801 10.6648L16.7706 6.23181H15.8711L12.5304 10.0865L9.89629 6.23181H6.8125L10.7957 12.0782L6.8125 16.7038H7.71194L11.1812 12.6564L14.008 16.7038H17.0918L12.9801 10.6648ZM11.7594 12.0782L11.3739 11.4999L8.16166 6.87427H9.57506L12.2091 10.6005L12.5946 11.1787L15.9996 15.9971H14.5862L11.7594 12.0782Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2007_1153"
                  x1="3.89421"
                  y1="3.33332"
                  x2="20.1756"
                  y2="19.6147"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#23004A" />
                  <stop offset="0.9565" stopColor="#020003" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
            </svg>
          </a>
        </li>
        {/* <li>
          <a href="https://www.youtube.com/">
            <svg
              width="22"
              height="15"
              viewBox="0 0 22 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.55615"
                y="2.93616"
                width="10.5129"
                height="8.17671"
                fill="white"
              />
              <path
                d="M21.0408 5.03874C21.0408 2.58573 19.055 0.599976 16.602 0.599976H5.15461C2.7016 0.599976 0.71582 2.58573 0.71582 5.03874V10.2952C0.71582 12.7482 2.7016 14.734 5.15461 14.734H16.602C19.055 14.734 21.0408 12.7482 21.0408 10.2952V5.03874ZM14.3826 8.07581L9.24296 10.6456C9.00934 10.7625 8.30848 10.6456 8.30848 10.412V5.27238C8.30848 5.03876 9.00934 4.92193 9.24296 5.03874L14.149 7.7254C14.3826 7.7254 14.6162 7.959 14.3826 8.07581Z"
                fill="#FF0D1C"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/">
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0751 7.96294C15.3981 8.87891 17.0265 9.48956 18.7567 9.48956V6.13098C18.4514 6.13098 18.0443 6.13099 17.739 6.02922V8.67536C16.0088 8.67536 14.3804 8.16648 13.0573 7.14873V14.0694C13.0573 17.5298 10.3094 20.2777 6.84902 20.2777C5.52595 20.2777 4.40642 19.8706 3.38867 19.26C4.5082 20.3795 6.1366 21.0919 7.86678 21.0919C11.3271 21.0919 14.0751 18.344 14.0751 14.8836V7.96294ZM15.2964 4.60436C14.5839 3.89193 14.1768 2.87419 14.0751 1.85643V1.44934H13.1591C13.3626 2.67064 14.1768 3.89193 15.2964 4.60436ZM5.52595 16.5121C5.11885 16.0032 4.9153 15.3925 4.9153 14.7819C4.9153 13.2552 6.23838 11.9322 7.765 11.9322C8.07033 11.9322 8.37565 11.9322 8.5792 12.0339V8.57359C8.27387 8.57359 7.96855 8.47181 7.56145 8.47181V11.118C7.25612 11.0162 6.9508 11.0162 6.64548 11.0162C5.11885 11.0162 3.79577 12.3393 3.79577 13.8659C3.99932 15.0872 4.60997 16.105 5.52595 16.5121Z"
                fill="#FF1753"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7388 6.02919V5.31676C16.8229 5.31676 16.0087 5.1132 15.1945 4.60433C15.9069 5.31675 16.8229 5.82564 17.7388 6.02919ZM13.159 1.34753C13.159 1.24575 13.159 1.04221 13.0572 0.940435V0.533325H9.69861V13.9676C9.69861 15.4943 8.37553 16.8173 6.8489 16.8173C6.34003 16.8173 5.93292 16.7156 5.52582 16.512C6.0347 17.2244 6.8489 17.6315 7.76488 17.6315C9.2915 17.6315 10.6146 16.4102 10.6146 14.7818V1.34753H13.159ZM7.66311 8.57356V7.75936C7.35778 7.75936 7.05245 7.65758 6.8489 7.65758C3.38855 7.65758 0.640625 10.4055 0.640625 13.8659C0.640625 16.0031 1.76015 17.9369 3.38855 19.0564C2.26902 17.9369 1.65838 16.4102 1.65838 14.6801C1.5566 11.4233 4.30453 8.67534 7.66311 8.57356Z"
                fill="#00C9D0"
              />
            </svg>
          </a>
        </li> */}
      </ul>
    </div>
  );
};

FooterHistory.displayName = "FooterHistory";

export default FooterHistory;
